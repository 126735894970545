import styled from 'styled-components'
import { grayscale, orange, white, gray } from '../../../../../../styles/colors'

type ButtonData = {
  active: boolean;
}

export const Section = styled.section`
`

export const Box = styled.div`
  border-radius: 8px;
  padding: 8px  16px 8px  16px ;
  background:  ${orange[100]};
  color: #181818;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  width: 107px;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`
export const Select = styled.div`
  position: relative;
  margin-top: 24px;
  position: relative ;

  svg {
    position: absolute;
    right: 10px;
  }

  .icon {
    transition: transform 0.5s ease-in-out;
  }
`

export const ButtonData = styled.button`
   background: none;
    padding: 8px 16px ;
    border: 1px solid ${grayscale[200]};
    width: 79px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    z-index: 2;
    background: ${(props: ButtonData) => props.active ? grayscale[500] : white};
    position: relative;
    span {
      color: ${(props: ButtonData) => props.active ? white : grayscale[500]};
    }
`

export const DataLabel = styled.div`
`

export const ButtonBox = styled.div`
  border-radius: 18px;
  border: 1px solid ${grayscale[400]};
  width: 84px;
  top: 50%;

  select {
    padding: 10px;
    border: none;
    background-color: transparent;
  }
`

export const Container = styled.div`
  background: ${gray[400]};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  margin-top: 24px;
  padding: 16px;
  border-bottom: 2px solid var(--light-neutral-grayscale-200, ${grayscale[200]});
`
export const Border = styled.hr`
  border: 1px solid ${grayscale[200]};
`
export const Displaying = styled.div`
    width: 128px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid ${grayscale[200]};
    display: flex;
    align-items: center;
    justify-content: center;

  select{
    border: none;
    appearance: none;
    padding: 6px;
    border-radius: 20px;
  }
`
export const Pagination = styled.button`
  border-radius: 8px;
  border: 1px solid ${grayscale[200]};
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
`
export const Page = styled.div`
  border-radius: 4px;
  width: 80px;
  height: 48px;
  background: ${gray[400]};
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px
`
